<template>
  <div>
    <el-dialog
      :title="etitle"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="80px"
      >
        <template v-if="type == 1">
          <el-form-item label="分类名称" prop="KindName">
            <el-input
              v-model="formData.KindName"
              placeholder="请输入分类名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类排序">
            <el-input v-model="formData.Sort" placeholder="选填"></el-input>
          </el-form-item>
        </template>

        <template v-else>
          <el-form-item label="标签名称" prop="TagName">
            <el-input
              v-model="formData.TagName"
              placeholder="请输入标签名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类">
            <el-select v-model="formData.KindGuid" @change="onChangeSelect">
              <el-option
                v-for="item in kindList"
                :key="item.KindGuid"
                :label="item.KindName"
                :value="item.KindGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import member from "@/api/member.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "添加",
    },

    kindList: {
      type: Array,
      default: () => [],
    },

    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      formData: {},
      formRules: {
        KindName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" },
        ],
        TagName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" },
        ],
      },
      showDialog: false
    };
  },
  methods: {
    // 关闭对话框
    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.showDialog = false
    },

    // 打开对话框
    onShowDialog(event){
      this.formData = event
      this.showDialog = true
    },

    onChangeSelect(event){
      // this.formData.KindGuid = event
      
    },

    // 提交事件
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let { type, formData, etitle } = this;
          try {
            let result = {};

            if (type == 1) {
              // 处理分类
              formData.Sort = formData.Sort || 0;
              formData.KindGuid = formData.KindGuid || "";
              result = await member.saveMemberTagKind(formData);
            } else if (type == 2) {
              // 处理标签 如果有 TagGuid 就是修改
              formData.TagGuid = formData.TagGuid || "";
              if (formData.TagGuid) {
                result = await member.updateMembertag(formData);
              } else {
                result = await member.addMembertag(formData);
              }
            }

            await this.$nextTick(() => {
              if (result.errcode == 0) {
                this.$emit("success");
                this.$message.success(etitle + "成功");
                this.onCloseDialog("formRules");
              }
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>
